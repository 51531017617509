/**
 * Utils functions
 **/
const lerp = function(a, b, n) { (1 - n) * a + n * b };

const throttle = function(func, limit=50) {
  let inThrottle;

  return (...args) => {
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  }
}

const getWindowSize = () => ({ width: window.innerWidth, height: window.innerHeight });

const getMousePos = e => ({ x: e.clientX, y: e.clientY });

const animationFloatingImages = (css_class) => {
  // First floatting images block
  let windowSize = {
    width: window.innerWidth,
    height: window.innerHeight
  };
  window.addEventListener('resize', throttle((e) => windowSize = getWindowSize(e)));

  let mousePos = {
    x: 0,
    y: 0
  };
  window.addEventListener('mousemove', e => throttle(mousePos = getMousePos(e)));

  const BREAKPOINT_WIDTH = 1200;

  /**
   * Floating images
   **/
  if($('.' + css_class + ' .floating-img').length) {
    const $floatingImgsContainer = document.querySelector('.' + css_class);
    const $floatingImgsInner = $floatingImgsContainer.querySelector('.floating-imgs-inner');
    const $floatingImgs = Array.from($floatingImgsInner.querySelectorAll('.floating-img'));
    let animateFloatingImgs = true;


    // Animate $floatingImgsInner (images direct parent) element translate x and y according to mouse position
    const translateFloatingImgs = () => {
      if ($floatingImgsInner) {
        if (animateFloatingImgs) {
          requestAnimationFrame(translateFloatingImgs);
        }

        let rect = $floatingImgsInner.getBoundingClientRect();

        if (css_class == 'floating-imgs-container' && window.scrollY < (rect.x + rect.height)) {
          let translateX = (mousePos.x + window.scrollX - (rect.left + rect.width / 2)) * .1;
          let translateY = (mousePos.y + window.scrollY - (rect.top + rect.height / 2)) * .1;

          $floatingImgsInner.style.setProperty("--translateX", `${translateX}px`);
          $floatingImgsInner.style.setProperty("--translateY", `${translateY}px`)
        } else if (css_class == 'floating-missions-imgs-container' && window.scrollY > (rect.x + rect.height)) {
          let translateX = (mousePos.x + window.scrollX - (rect.left + rect.width / 2)) * .05;
          let translateY = (mousePos.y + window.scrollY - (rect.top + rect.height / 2)) * .03;

          $floatingImgsInner.style.setProperty("--translateX", `${translateX}px`);
          $floatingImgsInner.style.setProperty("--translateY", `${translateY}px`)
        } else {
          $floatingImgsInner.style.setProperty("--translateX", '0px');
          $floatingImgsInner.style.setProperty("--translateY", '0px');
        }
      }
    }

    // Animate each image's scale according to the mouse position
    const scaleFloatingImgs = () => {
      if ($floatingImgs) {
        if (animateFloatingImgs) {
          requestAnimationFrame(scaleFloatingImgs);
        }

        $floatingImgs.forEach((image, index) => {
          let imgProps = image.getBoundingClientRect()
          let imgCenterX = imgProps.left + imgProps.width / 2
          let imgCenterY = imgProps.top + imgProps.height / 2

          // Img center position relative to window in percentage
          let imgPosX = 100 * imgCenterX / windowSize.width
          let imgPosY = 100 * imgCenterY / windowSize.height

          // Cursor position relative to window in percentage
          let cursorPosX = 100 * mousePos.x / windowSize.width
          let cursorPosY = 100 * mousePos.y / windowSize.height

          // Distance between the mouse and the image's center in percentage
          let distanceFromImgCenter = {
            x: Math.abs(imgPosX - cursorPosX),
            y: Math.abs(imgPosY - cursorPosY)
          }

          const maxDist = 60;

          if (distanceFromImgCenter.x < maxDist && distanceFromImgCenter.y < maxDist) {
            let proximityMultiplier = parseInt(100 * (maxDist - (distanceFromImgCenter.x + distanceFromImgCenter.y)) / maxDist - 40, 10);
            if (proximityMultiplier < 10) proximityMultiplier = '0';

            image.style.setProperty("--scale", `1.${proximityMultiplier}`)
          } else {
            image.style.setProperty("--scale", "1")
          }
        })
      }
    }

    if(windowSize.width > BREAKPOINT_WIDTH) {
      scaleFloatingImgs();
      translateFloatingImgs();
    }

    const runFloatingImages = () => {
      animateFloatingImgs = true;
      requestAnimationFrame(scaleFloatingImgs);
      requestAnimationFrame(translateFloatingImgs);
    }
    const resetFloatingImages = () => {
      if ($floatingImgs) {
        animateFloatingImgs = false;

        $floatingImgs.forEach(image => image.style.removeProperty("--scale"));
        $floatingImgsInner.style.removeProperty("--translateX");
        $floatingImgsInner.style.removeProperty("--translateY");
      }
    }

    window.addEventListener('resize', throttle(() => {
      windowSize.width < BREAKPOINT_WIDTH ? resetFloatingImages() : runFloatingImages()
    }));
    window.addEventListener('scroll', throttle(() => {
      let rect = document.querySelector('.' + css_class).getBoundingClientRect();

      Math.abs(rect.top) > rect.height / 2 ||
        windowSize.width < BREAKPOINT_WIDTH ? resetFloatingImages() : runFloatingImages();
    }));
  }
}

window.addEventListener("DOMContentLoaded", (event) => {
  $('.article-join:not(.member-form) button').on('click', function (event) {
    var parentDiv = $(event.currentTarget).parents('.article-join');
    $(parentDiv).toggleClass('active');
  });

  $.fn.datetimepicker.dates['fr-FR'] = {
    days: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"],
    daysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
    daysMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"],
    months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    monthsShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aou", "Sept", "Oct", "Nov", "Déc"],
    today: "Aujourd'hui"
  };

  $('.js-datetimepicker').each(function(index) {
    $(this).datetimepicker({
      language: $(this).data('locale'),
      pickSeconds: false,
      format:'dd/MM/yyyy hh:mm:00',
      timeFormat: "hh:mm:00"
    });
  });

  $('.toast').toast("show");

  $('.js-hidden-check').on('change', function() {
    $(this).parents('div.btn').toggleClass('selected');
  });

  $('.js-custom-link').on('click', function() {
    var link = $(this).find('a');

    if(link.length) {
      var href = $(link).attr('href');
      window.location = href;
    }
  });

  animationFloatingImages('floating-imgs-container');
  animationFloatingImages('floating-missions-imgs-container');

  /**
   * News images
   **/
  if($('.news-inner').length) {
    const $news = Array.from(document.querySelectorAll(".news-inner"));
    $news.forEach(($new) => {
      const config = {
        duration: 1,
        fill: "both",
        timeline: new ScrollTimeline({
          scrollSource: document.documentElement,
          timeRange: 1,
          fill: "both",
          scrollOffsets: [{
              target: $new,
              edge: 'end',
              threshold: 0
            },
            {
              target: $new,
              edge: 'start',
              threshold: 0
            },
          ],
        }),
      }

      // Animate image container's rotation on scroll
      // $new.animate({
      //   transform: ['rotate(5deg) rotateX(20deg)', 'rotate(-5deg) rotateX(-20deg)']
      // }, {...config});

      // Animate image's scale on scroll
      $new.querySelector('.news-img img').animate({
        // transform: ['scale(1.3)', 'scale(1)', 'rotate(5deg) rotateX(20deg)', 'rotate(-5deg) rotateX(-20deg)']
        transform: ['rotate(5deg) rotateX(20deg)', 'rotate(-5deg) rotateX(-20deg)']
      }, {...config});
    });
  }

  $('.js-obf').on('click', function() {
    var url = atob($(this).data('road'));

    if($(this).data('target') == '_blank') {
      window.open(url, '_blank').focus();
    } else {
      window.location = url;
    }
  });

  if($('.js-showcase-event').length) {
    if($('.js-showcase-event input[type=checkbox]').prop('checked')) {
      $('.js-datetimepicker').parent().hide();
    }

    $('.js-showcase-event input[type=checkbox]').on('change', function(event) {
      if(event.target.checked) {
        $('.js-datetimepicker').parent().hide();
      } else {
        $('.js-datetimepicker').parent().show();
      }
    });
  }

  if($('.member-search-form').length) {
    $('.member-search-form input[type=checkbox]').on('click', function (e) {
      if(e.currentTarget.checked == true) {
        $('.member-search-form').append("<input type='text' name='members-list' value='true' />");
        $('.member-search-form').submit();
      }
    });
  }

  $('.js-toggle-podcasts').on('click', function() {
    if($(this).attr('aria-expanded') == "true") {
      $(this).find('span.text').text($(this).data('visible-text'));
    } else {
      $(this).find('span.text').text($(this).data('hidden-text'));
    }
  });
});
