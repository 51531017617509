// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("jquery")
var Trix = require("trix")
require("@rails/actiontext")
import 'bootstrap'
import '../js/datetimepicker.js'
import '../js/front.js'

import "@fortawesome/fontawesome-free/css/all"
import '../scss/datetimepicker.css'
import '../scss/front.scss'
import '../../assets/stylesheets/application.scss'
import '../../assets/stylesheets/actiontext.scss'

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

Rails.start()
ActiveStorage.start()
